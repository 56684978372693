.donate-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.donation-options {
    margin-top: 40px;
    text-align: center;
}

.donorbox-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}

.donorbox-iframe {
    max-width: 400px;
    min-width: 250px;
    width: 100%;
    height: 900px;
    border: none;
}

.donorbox-button {
    background-color: #5180C0; /* Button color */
    color: #ffffff;
    text-decoration: none;
    font-family: 'KCStrikerSans-Inked', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 8px 24px;
    border-radius: 5px;
    gap: 8px;
    width: fit-content;
    line-height: 24px;
    margin: 20px auto; /* Center the button */
}

.donorbox-button img {
    margin-right: 8px; /* Adjust spacing around the logo */
}


.submit-button {
    background-color: #1B7D76; /* Color from your palette */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #165a5b; /* Darker shade for hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .donorbox-container {
        flex-direction: column;
        align-items: center;
    }

    .donorbox-iframe {
        width: 100%;
        height: auto; /* Adjust height for better mobile experience */
    }

    .donorbox-button {
        width: 100%;
        justify-content: center;
    }
}
