/* Import Google Fonts and Custom Fonts */
/* (Your font imports remain the same) */
@font-face {
  font-family: "SuperKinds";
  src: url("./fonts/SuperKinds.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Livvic";
  src: url("./fonts/Livvic/Livvic-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Livvic";
  src: url("./fonts/Livvic/Livvic-Bold.ttf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Livvic";
  src: url("./fonts/Livvic/Livvic-SemiBold.ttf") format("opentype");
  font-weight: semi-bold;
  font-style: normal;
}

@font-face {
  font-family: "Livvic";
  src: url("./fonts/Livvic/Livvic-Light.ttf") format("opentype");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Livvic";
  src: url("./fonts/Livvic/Livvic-ExtraLight.ttf") format("opentype");
  font-weight: extra-light;
  font-style: normal;
}

@font-face {
  font-family: "Livvic";
  src: url("./fonts/Livvic/Livvic-Medium.ttf") format("opentype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "KCStrikerSans-Inked";
  src: url("./fonts/KCStrikerSans-Inked.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KCBubbleShack";
  src: url("./fonts/KCBubbleShack.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Defining color variables for easier management */
:root {
  --primary-color: #cfeaf9; /* #F9DDCB Light background for the header */
  --highlight-color: #5180c0; /* Dark green for text */
  --background-color: #cfeaf9; /* Background color for the body */
  --text-color: #5180c0; /* Black for primary text */
  /* --primary-color: #D4BBC8; 
    --highlight-color: #4C5849; 
    --background-color: #9ABCC2; 
    --text-color: #000000;*/
}

/* General page styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Livvic", sans-serif;
  background-color: var(--background-color);
  color: var(--highlight-color);
  line-height: 1.5; /* Improved line spacing */
}

.content {
  padding: 20px; /* Adds padding around main content */
}

h2 {
  font-family: "KCStrikerSans-Inked", sans-serif;
}

p {
  margin: 0 0 1em 0; /* Adds space between paragraphs */
  font-family: "Livvic", sans-serif;
  font-weight: medium;
  line-height: 1.6;
}

/* Header styles */
.header {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--highlight-color);
  font-family: "KCStrikerSans-Inked", sans-serif;
  letter-spacing: 4px;
  width: 100%;
  height: auto;
}

/* Logo container */
.header-logo {
  flex: 0 0 25%; /* Occupies 25% of the header width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo img {
  max-width: 80%; /* Adjust as needed */
  height: auto;
  padding-left: 25px;
}

/* Header content (text and navigation) */
.header-content {
  flex: 0 0 75%; /* Occupies 75% of the header width */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header-text {
  margin: 0;
  font-family: "Livvic", sans-serif;
  font-weight: 600;
}

.header-text h1,
.header-text h3 {
  margin: 5px 0;
  padding-bottom: 10px;
}

/* Navigation Bar within the header */
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 10px 0;
}

.nav-links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 15px;
}

.main-title {
  font-family: "KCStrikerSans-Inked", sans-serif;
  letter-spacing: 4px;
  color: var(--highlight-color);
}

/* Apply bold 'Livvic' font to the subtitle */
.subtitle {
  font-family: "Livvic", sans-serif;
  font-weight: bold;
  color: var(--highlight-color);
}

/* Apply regular 'Livvic' font to the NavBar links */
.nav-links a {
  font-family: "Livvic", sans-serif;
  font-weight: bold;
  color: var(--highlight-color);
  text-decoration: none;
  font-size: large;
}

/* Menu icon for mobile */
.menu-icon {
  display: none;
}

.footer {
  padding: 20px;
  color: var(--highlight-color);
}

.email-link a {
  color: #5180c0;
  text-decoration: none;
  font-weight: bold;
}

/* Style the close-menu list item */
.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Style the close button */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #5180c0;
}

/* Responsive Layout Adjustments */
@media (max-width: 768px) {
  /* .header {
        flex-direction: column;
        align-items: center;
    }

    .header-logo {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 25px;
    }

    .header-logo img {
        width: 70%;
    } */

  .header {
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    text-align: center;
    /* padding: 10px; */
  }

  .header-logo {
    margin-bottom: 10px;
  }

  .header-logo img {
    width: 60%;
    align-self: center;
    padding-left: 0px;
    padding-top: 20px;
  }

  .header-content {
    flex: 0 0 auto;
    width: 90%;
    padding-bottom: 20px;
  }

  .navbar {
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }

  .menu-icon {
    display: block;
    font-size: 24px;
    color: var(--highlight-color);
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%); /* Center vertically within the header */
  }

  .nav-links {
    padding: 10px;
    flex-direction: column;
    align-items: center;
    display: none;
    background-color: var(--primary-color);
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
  }

  .nav-links a {
    font-family: "Livvic", sans-serif;
    font-weight: bold;
  }

  .nav-links.open {
    display: flex;
  }

  .footer {
    padding: 20px;
    text-align: center;
    color: var(--highlight-color);
  }
}

/* Existing styles remain the same */

/* Styles for screens wider than 1100px */
@media (min-width: 1100px) {
  .header-text h1 {
    font-size: 2.5em;
    margin: 15px 0;
  }

  .header-text h3 {
    font-size: 1.5em;
    margin: 10px 0 20px 0;
  }

  .header-content {
    padding: 30px 0;
    max-width: 1200px;
    margin: 0 auto;
  }

  .nav-links a {
    font-size: 1.2em;
  }

  .header-logo img {
    max-width: 90%;
  }
}

/* Styles for screens wider than 1400px */
@media (min-width: 1400px) {
  .header-text h1 {
    font-size: 3em;
    margin: 20px 0;
  }

  .header-text h3 {
    font-size: 1.8em;
    margin: 15px 0 25px 0;
  }

  .header-content {
    padding: 40px 0;
  }

  .nav-links a {
    font-size: 1.4em;
  }

  .header-logo img {
    max-width: 100%;
    padding-left: 25px;
  }
}
