.container {
    padding: 20px;
}

.profileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.imageContainer {
    flex: 1;
    max-width: 400px;
    margin-right: 20px;
}

.textContainer {
    flex: 2;
    min-width: 300px;
    text-align: left;
}

.image {
    width: 100%;
    max-width: 400px;
    height: auto;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .profileContainer {
        flex-direction: column;
    }

    .imageContainer {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .textContainer {
        text-align: center;
    }
}
